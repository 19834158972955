<template>
    <div style="width:100%;height:100%;position: relative;">
        <section class="mainBox">
            <ul>
                <li @click="showChat = !showChat">
                    <img style="width:35px;" src="@/assets/img/news.jpeg" alt="">
                    <div>在线咨询</div>
                </li>
                <li @mouseover="showHoverTel = true" 
                    @mouseleave="showHoverTel = false">
                    <img style="width:35px;" src="@/assets/img/u8.svg" alt="">
                    <div>联系我们</div>
                </li>
                <li style=" margin: 10px 5px" 
                    @mouseover="showHoverCard = true" 
                    @mouseleave="showHoverCard = false">
                    <img style="width:35px;" src="@/assets/img/u9.svg" alt="">
                    <div>产品顾问</div>
                </li>
            </ul>
        </section>
        <section>
            <div class="productCard" v-show="showProductCard || showHoverCard">
                <div>扫码添加微信好友</div>
                <img style="width:180px;" src="@/assets/img/saoma.png" alt="">
            </div>
            <div class="productTel" v-show="showProductTel || showHoverTel">
                <div><i class="el-icon-phone-outline"></i> 联系电话</div>
                <p>185-0716-9976</p>
            </div>
            <!-- <socialChat class="showChat" v-show="showChat" /> -->
        </section>
    </div>
</template>

<script>
// import  socialChat  from '@/polk/src/components/imClient/imClient'
export default { 
    components: {
        // socialChat
    },
    methods: {
 
    },
    data() {
        return {
            showChat: false,
            showProductCard: false,
            showHoverCard: false,
            showHoverTel: false,
            showProductTel: false,
            feed: [
                {
                    id: 0,
                    author: 'Person',
                    contents: 'hi there',
                    date: '16:30'
                },
                {
                    id: 1,
                    author: 'Me',
                    contents: 'hello',
                    date: '16:30'
                }
            ]
        }
    },
}
</script>

<style scoped lang="less">
.mainBox {
    width: 70px;
    border-radius: 5px;
    background: rgb(222, 246, 255);
    ul {
        margin: 0;
        padding: 0;
        width: 100%;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        li {
            margin: 10px 5px 0;
            padding: 5px;  
            display: flex;
            flex-direction: column;
            align-items: center;  
            background: #fff;
            border-radius: 3px;
            cursor: pointer;
            div {
                font-size: 12px;
            }
        }
    }
}
.productCard {
    width: 200px;
    height: 217px;
    border: 3px solid rgb(222, 246, 255);
    position: absolute;
    left: -210px;
    bottom: 0;
    background: #fff;
    border-radius: 6px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    div {
        font-size: 20px;
        font-weight: bold;
    }
}
.productTel {
    width: 200px;
    height: 58px;
    border: 3px solid rgb(222, 246, 255);
    position: absolute;
    left: -210px;
    bottom: 80px;
    background: #fff;
    border-radius: 6px;
    z-index: 8;
    div {
        font-size: 22px;
        font-weight: bold;
        padding-left: 10px;
        color: #000;
    }
    p {
        margin: 6px 0;
        font-size: 20px;
        padding-left: 10px;
        line-height: 20px;
        color: blue;
    }
}
.showChat {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background: #fff;
    // border-radius: 15px;
    z-index: 4000;
}

</style>
