<template>
    <div id="dom" class="body">
      <header>
        <img class="navImg" src="@/assets/img/u0.jpg">
        <div class="navRight">
          <div style="display:flex">
            <img src="@/assets/img/u8.svg" alt="">
            <img src="@/assets/img/u9.svg" alt="">
          </div>
          <div>185-0716-9976</div>
        </div>
      </header>
      <section class="navs">
        <ul>
            <router-link to="/home"><li style="margin-left: 0;" :class="active === 1?'active':''" >首页</li></router-link>
            <router-link to="/product"><li :class="active === 2?'active':''" >产品</li></router-link>
            <router-link to="/case"><li :class="active === 3?'active':''" >案例</li></router-link>
            <!-- <li :class="active === 4?'active':''" >资源</li>
            <li :class="active === 5?'active':''" >招聘</li>
            <li :class="active === 6?'active':''" style="position: absolute;right:20px;width:100px">关于我们</li> -->
        </ul>
      </section>
      <main>
        <section style="margin-bottom: 10px">
          <el-carousel :interval="5000" arrow="always" height="150px">
            <el-carousel-item v-for="(item,index) in imgList" :key="index">
              <img style="width:100%" :src="item.img" />
            </el-carousel-item>
          </el-carousel>
        </section>
        <p>   
            武汉力源达信息技术有限公司2010年9月在武汉东湖高新区注册成立，
            公司成立之日起是致力于专业IT外包人力服务，通过和业界知名IT公司合作，完成项目软件开发和准时交付。    
        </p>
        <p>
            2017年公司战略转型打造自有知识产权产品的高科技公司。战略性布局自有知识产权的快速开发平台，
            并基于此平台开发出在跨行业营销、智能制造、运维服务管理上 三大产品系列。目前已经注册推广的软件产品，
            包括：EDP快速开发平台、 ITIL运维管理平台、DEP数据交换平台、工作流引擎BPM等。
        </p>
        <section class="imageList">
          <img src="@/assets/img/u15.png" />
          <img src="@/assets/img/u16.svg" />
          <img src="@/assets/img/u19.png" />
          <img src="@/assets/img/u20.png" />
          <img src="@/assets/img/u21.jpg" />
          <img src="@/assets/img/u22.png" />
        </section>
      </main>
      <footer>
        <div class="text">相互相成 真心服务</div>
        <img src="@/assets/img/u23.png" />
        <div>
          <span style="text-align: left;">关键字：低代码 配置化 开发工具 开发平台 数据交换 可视化 异构数据库交换平台 企业管理软件</span>
          <span style="text-align: right;">武汉力源达信息技术有限公司</span>
          <span style="text-align: center;font-size: 10px;line-height: 20px;">copyright © 武汉力源达信息技术有限公司版权所有 | ICP主体备案号：鄂ICP备19030060号 | 备案状态：正常 </span>
        </div>
      </footer>
      <section style="position: fixed;right: 5%;top:40%;">
          <suspension  />
      </section>
      <el-button   type="info" icon="el-icon-caret-top" 
                  circle class="backTop" 
                  v-show="flag_scroll" @click="backTop()"></el-button>
    </div>
</template>

<script>
import suspension from '@/components/common/suspension'
export default { 
    components: {
        suspension
    },
    mounted() {
      //滚动监听
      window.addEventListener('scroll', this.handleScroll, true)
    },
    //销毁滚动监听
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll, true)
    },
    methods: {
      //置顶
      backTop(){
          document.getElementById('dom').scrollIntoView()
      },
      //监听滚动事件
      handleScroll(){
        if(document.body.scrollTop > 500){
          this.flag_scroll = true
        }else {
          this.flag_scroll = false
        }
      },
    },
    data() {
      return {
        active:1,
        flag_scroll: false,
        imgList: [
          {
            img:require('@/assets/img/u28.gif'),
          },
          {
            img:require('@/assets/img/u30.png'),
          },
          {
            img:require('@/assets/img/u31.png'),
          }, 
        ]
      }
    },
}
</script>

<style scoped lang="less">
.body {
    position: relative;
    margin: 0px;
    background-image: none;
    position: relative;
    left: 0px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    height: 100%;
}
header {
  position: relative;
  display: flex;
  .navImg {
    width: 100%;
  }
  .navRight {
    position: absolute;
    right: 0;
    img {
      margin-right: 30px;
    }
  }
}
footer {
    position: relative;
    width: 100%;
    // height: 200px;
    .text {
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translate(-50%,0);
        font-size: 35px;
        text-align: center;
        margin: 0 auto;
        width: 300px;
        background: rgba(215, 215, 215, 0);
    }
    img {
      width: 100%;
    }
    div {
      display: flex;
      flex-direction: column;
      background: rgba(215, 215, 215, 1);
      margin-top: -5px;
      padding: 0 20px;
      span {
        line-height: 40px;
      }
    }
}
main {
    p {
        font-size: 20px;
        text-indent:2em;
        letter-spacing:2px;
        margin: 0;
    }
}
.navs {
    width: 100%;
    background: rgba(0, 147, 208, 1);
    ul {
      display: flex;
      list-style: none;
      height: 45px;
      line-height: 45px;
      margin-bottom: 5px;
      color: #fff;
      position: relative;
      li {  
        width: 60px;
        margin-left: 70px;
        text-align: center;
        cursor: pointer;
        &:hover {
          width: 60px;
          margin-top: 2.5px;
          height: 40px;
          line-height: 40px;
          background: #fff;
          border-radius: 5px;
          font-size: 20px;
          color: rgba(0, 147, 208, 1);
        }

      }
    }
}
.active {
    width: 60px;
    margin-top: 2.5px;
    height: 40px;
    line-height: 40px;
    background: #fff;
    border-radius: 5px;
    font-size: 20px;
    color: #0093d0;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    // line-height: 300px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.imageList {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  img {
    width: 100%;
  }
}

a {
    color: #fff!important;
    text-decoration: none!important;
}
 
.router-link-active {
    color: #fff!important;
    text-decoration: none!important;
}
.backTop {
    position: fixed;
    right: 5%;
    bottom: 5%;
    z-index: 99;
}
</style>
